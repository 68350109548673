<template lang="pug">
.absolute.bottom-0.top-0.left-0.z-30.bg-white.shadow.grid.grid-cols-2.pr-4.pl-6.pt-5.w-module(
  style='grid-template-rows: auto auto auto auto 1fr'
)
  .text-darkblue.col-start-1.col-end-2.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ $t('qrAdminTranslater.qrCreate') }}
  .flex.justify-end.col-start-2.items-center
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='$emit("close")'
    )

  .mt-8.flex.flex-col.col-start-1.col-end-3.items-center.justify-between
    .flex.flex-col.w-full.justify-center.text-center
      .flex.flex-col.qr-title.mb-6.sm_bg-green-500(class=' ')
        span {{ $t('qrAdminTranslater.register') }}
        span {{ $t('qrAdminTranslater.addGps') }}
      qrcode-vue#qrcode.qr-code.flex.justify-center.mb-8(
        :value='link',
        level='H',
        :size='200'
        render-as='svg'
      )
      .w-full.bg-darkblue.px-4.pb-4.text-white
        span.mb-4.code {{ codeGenerated }}
        .input-container(style='border-radius: 10px')
          el-input(
            type='textarea',
            :rows='6',
            v-model='customText'
          )
    .flex.w-full.justify-end.mt-8
      skif-button(@click="print") {{ $t('qrAdminTranslater.print') }}
  </template>

<script>
// import { mapState } from 'vuex'

import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    infoIcon: () => import('@/assets/svg-icons/info.svg'),
    QrcodeVue
  },
  props: {
    unitBox: { type: Object, default: () => {} },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    codeGenerated: '',
    link: ''
  }),
  computed: {
    customText() {
      return this.$t('qrAdminTranslater.scanCode')
    }
  },
  watch: {},

  methods: {
    print() {
      const yourDOCTYPE = '<!DOCTYPE html>'
      const printPreview = window.open('', 'print_preview')
      const printDocument = printPreview.document
      const code = document.getElementById('qrcode').outerHTML
      printDocument.open()
      const head = ` <head>
        <style>
         .to-print{

            width:100%;
            //  height:279mm;
            //   width:80mm;
            font-family: Source Sans Pro;
              display:flex;
              align-items:center;
              flex-direction:column;
           }
          .codeGenerated{
              font-size:55px;
              font-family: Lato;
              display:inline-block;
              margin-bottom:0px;
              margin-top:10px

            }
          .customText{
            font-size:26px;
              width:60%;
              line-height:1.5;
              text-align:center;

              font-style: normal;
              font-weight: bold;
            }
          .qr-title{
            display:flex;
              flex-direction:column;
              align-items:center;
              justify-content:center
              font-weight: 700;
            line-height:1.5;
              font-size: 28px;
              font-family: Source Sans Pro;
              margin-bottom:30px;

          }
        </style>
        </head>`
      printDocument.write(
        `${yourDOCTYPE}<html>${head}<body>` +
          `<div class='to-print'>` +
          `<div class='qr-title'>` +
          `<span >${this.t('qrAdminTranslater.register')}</span> ` +
          `<span> ${this.t('qrAdminTranslater.addGps')}</span>` +
          `</div>${code}<p class="codeGenerated">${this.codeGenerated}</p>` +
          `<p class="customText">${this.customText}</p> ` +
          `</div>` +
          `</body>` +
          `</html>`
      )
      printPreview.print()
      printPreview.close()
    }
  },
  created() {
    this.codeGenerated = this.unitBox.code
    this.link = this.unitBox.link
  }
}
</script>

<style lang="stylus" scoped>
.code {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 65px;
  line-height: 82px;
  text-align: right;
  letter-spacing: 0.03em;
}
.qr-title{
  font-family: Lato;
font-weight: 800;
font-size: 26px;

}
</style>
